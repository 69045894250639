<template>
  <div>
    <b-card no-title>
      <b-card-title>
        {{ $t('card.title.add.phoneNumber') }}
      </b-card-title>
      <b-card-text>
        {{ $t('card.description.add.phoneNumber') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
      <b-card-code>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group
                    :label="$t('form.phoneNumber')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.phoneNumber').toLowerCase()"
                      rules="required"
                      vid="phoneNumber"
                  >
                    <b-form-input
                        v-model="formData.phoneNumber"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.phoneNumber').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  {{ $t('label.button.save') }}
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
      <help-modal/>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BForm, BRow, BCol, BButton, BFormCheckbox, BOverlay, BFormTextarea, BLink,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import helpModal from '@/views/pages/_components/_modal/areaCode/help.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    BFormTextarea,
    BLink,
    helpModal,
    vSelect,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'phoneNumber',
      formData: {
        phoneNumber: '',
      },
      loading: false,
      required,
    }
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('storePhoneNumber', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'modify-phoneNumber', params: {id: response.data.entity.id}})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>
